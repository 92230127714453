import ContainerMain from "src/pages/common/ContainerMain"
import "./Introduction.scss"
import { useFormatter } from "helpers/i18n"
import Content from "../../../common/Content"
import SectionTitle from "../../../common/SectionTitle"

export default function Introduction() {
  const { __ } = useFormatter()
  return (
    <div className="career-introduction">
      <div className="WdZHPaJUsL">
        <ContainerMain>
          <SectionTitle
            title={__({ defaultMessage: "Company Introduction" })}
          ></SectionTitle>
          <Content>
            <div className="video-container">
              <iframe
                className="youtube-video"
                src="https://www.youtube.com/embed/QvfW1asLu18"
                title="OnPoint Vietnam"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </Content>
        </ContainerMain>
      </div>
    </div>
  )
}
